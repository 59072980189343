import { createRouter, createWebHistory } from 'vue-router'
import { apiToken, walletConnected } from '@/composables/auth.ts'
import { LingoRouteName, lingoRoutePath } from '@/router/routes.ts'
import { isTGE, prefix } from '@/router/tge.ts'

const pathAndName = (routeName: LingoRouteName) => ({
  path: lingoRoutePath[routeName],
  name: routeName,
})

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/claim',
      redirect: lingoRoutePath[LingoRouteName.CLAIMING_DASHBOARD],
    },
    {
      path: '/',
      redirect: lingoRoutePath[LingoRouteName.CLAIMING_DASHBOARD],
    },
    {
      ...pathAndName(LingoRouteName.DASHBOARD),
      redirect: lingoRoutePath[LingoRouteName.CLAIMING_DASHBOARD],
    },
    {
      path: `${prefix}/claiming-dashboard`,
      meta: { requiresAuth: true },
      children: [
        {
          ...pathAndName(LingoRouteName.CLAIMING_DASHBOARD),
          component: () => import('../views/ClaimingDashboardView.vue'),
          meta: {
            requiresAuth: true,
            showNav: true,
          },
        },
        {
          ...pathAndName(LingoRouteName.CLAIMING),
          component: () => import('../views/ClaimWizardView.vue'),
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: `${prefix}/staking-dashboard`,
      meta: { requiresAuth: true },
      children: [
        {
          ...pathAndName(LingoRouteName.STAKING_DASHBOARD),
          component: () => import('../views/StakingDashboard.vue'),
          meta: {
            requiresAuth: true,
            showNav: true,
          },
        },
        {
          ...pathAndName(LingoRouteName.STAKING),
          component: () => import('../views/StakeWizardView.vue'),
          meta: { requiresAuth: true },
        },
        {
          ...pathAndName(LingoRouteName.STAKING_RENEW),
          component: () => import('../views/StakeRenewView.vue'),
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      ...pathAndName(LingoRouteName.BUY),
      component: () => import('../views/BuyWizardView.vue'),
      meta: {
        showNav: true,
      },
    },
    {
      ...pathAndName(LingoRouteName.START_EARNING),
      component: () => import('../views/StartEarningView.vue'),
      meta: {
        showNav: true,
      },
    },
    {
      ...pathAndName(LingoRouteName.TERMS_AND_CONDITIONS),
      component: () => import('../views/TermsAndConditionsView.vue'),
    },
    {
      ...pathAndName(LingoRouteName.PRIVACY_POLICY),
      component: () => import('../views/PrivacyPolicyView.vue'),
    },
    {
      ...pathAndName(LingoRouteName.CONNECT_WALLET_V2),
      component: () => import('../views/ConnectWalletView.vue'),
      meta: {
        showNav: true,
      },
    },
    {
      ...pathAndName(LingoRouteName.CONNECT_WALLET),
      component: () => import('../views/DashboardStarter.vue'),
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('../views/PageNotFound.vue'),
    },
  ],
})

export default router
router.beforeEach((to, _from, next) => {
  if (to.path.startsWith('/z3/') || isTGE) {
    if (to.meta.requiresAuth) {
      if (apiToken.value && walletConnected.value) {
        return next()
      } else {
        return next({ name: LingoRouteName.CONNECT_WALLET_V2, query: { redirect: to.fullPath } })
      }
    } else {
      return next()
    }
  } else {
    if (to.name !==LingoRouteName.CONNECT_WALLET) {
      if (!!apiToken.value && !!walletConnected.value) {
        return next()
      } else {
        return next({ name: LingoRouteName.CONNECT_WALLET })
      }
    } else {
      return next()
    }
  }
})
