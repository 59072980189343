const baseContracts: Record<string, { stakingAddress: `0x${string}`, vestingAddress: `0x${string}`, tokenAddress: `0x${string}` }> = {
  dev: {
    stakingAddress: '0xCaA3a2cC03fFB2eD3326cA01940E94aAf3793F93',
    vestingAddress: '0x5430124d492B10bf97fD4EAE7A32bE7C384b2306',
    tokenAddress: '0x0c7682E2aBeAC73e7016693e12b4f15AdbD07AB3',
  },
  prod: {
    stakingAddress: '0x9aF8C0dac726CcEE2BFd6c0f3E21f320d42398AC',
    vestingAddress: '0xAd11F733E401E16C72033c5DECAf05dcC0e1BEB8',
    tokenAddress: '0xfb42Da273158B0F642F59F2Ba7cc1d5457481677',
  },
}
export const appEnv = import.meta.env.VITE_APP_ENV as string
export const env = {
  app: {
    url: import.meta.env.VITE_APP_URL as string,
    isProd: appEnv === 'prod',
  },
  api: {
    baseUrl: import.meta.env.VITE_API_BASE_URL as string,
  },
  contracts: {
    base: baseContracts[appEnv],
    rpcEndpoint: import.meta.env.VITE_RPC_ENDPOINT as string,
  },
  sentryDsn: import.meta.env.VITE_SENTRY_DSN as string,
  mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN as string,
}
