import { computed, ref } from 'vue'
import { readContract } from '@wagmi/core'
import vestingContractDefinition from '@/abi/TokenVesting.json'
import { walletConnected } from '@/composables/auth.ts'
import { beneficiaryMapping } from '@/types/beneficiary.ts'
import { formatEther, parseEther } from 'viem'
import { env } from '@/env.ts'
import tokenContractDefinition from '@/abi/LingoToken.json'
import { config } from '@/composables/wallet/wallet-init.ts'
import api, { Stake } from '@/services/api.ts'
import { Snapshot } from '@/types/snapshot.ts'

export const snap = ref<Snapshot | null>(null)
export const myStakes = ref<{ stakes: Stake[], powers: { total: string } & Record<string, string> } | null>(null)
const totalClaimable = ref<BigInt>(BigInt('0'))
const totalClaimed = ref(BigInt('0'))
const tokenAvailable = ref(BigInt('0'))

export const useBalance = () => {
  const loadingData = ref(false)

  async function getSnapshot() {
    if (snap.value) return
    loadingData.value = true
    try {
      const { snapshot } = await api.getSnapshotV2()
      snap.value = snapshot
    } finally {
      loadingData.value = false
    }
  }

  const totalTokenAllocation = computed(() => snap.value?.totalTokenAllocation || 0)
  const allocationTypes = computed(() => snap.value?.allocationTypes || null)

  const hasAirdrop = computed(() => {
    if (!allocationTypes.value) return false
    return Object.keys(allocationTypes.value).some((key) =>
      /airdrop/i.test(key),
    )
  })

  // Stakes

  async function getMyStakes() {
    try {
      myStakes.value = await api.getStakes()
    } catch (error) {
      console.error('Error fetching lock config:', error)
    }
  }

  const hasStakes = computed(() => {
    if (myStakes.value?.stakes) {
      return myStakes.value?.stakes.length > 0
    } else {
      return null
    }
  })

  // Claimable balance

  async function getClaimableBalance(vestingType: string) {
    if (!snap.value) return
    return await readContract(config, {
      abi: vestingContractDefinition.abi,
      functionName: 'claimableTokenOf',
      args: [walletConnected.value, beneficiaryMapping[vestingType], parseEther(String(snap.value.allocationTypes[vestingType]))],
      address: env.contracts.base.vestingAddress,
    }) as bigint
  }

  async function checkClaimable(vestingType: string) {
    const claimableToken = await getClaimableBalance(vestingType)
    return claimableToken !== BigInt('0')
  }

  const totalClaimableBalance = computed(() =>{
    return Number(formatEther(BigInt(String(totalClaimable.value))))
  })
  const loadingClaimableBalance = ref(false)

  async function getAllClaimable() {
    if (!snap.value) {
      await getSnapshot()
      if (!snap.value) return
    }
    loadingClaimableBalance.value = true
    const claimableBalances = await Promise.all(
      Object.keys(snap.value.allocationTypes).map(getClaimableBalance),
    )

    totalClaimable.value = claimableBalances.reduce((acc: bigint, claimable: bigint | undefined) =>
      claimable && claimable !== BigInt(0) ? acc + claimable : acc, BigInt(0),
    )
    loadingClaimableBalance.value = false
  }

  // Claimed balance

  const totalClaimedBalance = computed(() => Number(formatEther(BigInt(String(totalClaimed.value)))))
  const loadingClaimedBalance = ref(false)

  async function getClaimedBalance(vestingType: string) {
    if (!snap.value) {
      await getSnapshot()
      if (!snap.value) return
    }
    return await readContract(config, {
      abi: vestingContractDefinition.abi,
      functionName: 'claimedTokens',
      args: [walletConnected.value, beneficiaryMapping[vestingType]],
      address: env.contracts.base.vestingAddress,
    }) as bigint
  }

  async function getAllClaimedBalance() {
    if (!snap.value) {
      await getSnapshot()
      if (!snap.value) return
    }
    loadingClaimedBalance.value = true
    const claimedBalances = await Promise.all(
      Object.keys(snap.value.allocationTypes).map(getClaimedBalance),
    )

    totalClaimed.value = claimedBalances.reduce((acc: bigint, claimed: bigint | undefined) => {
      return claimed && claimed !== BigInt(0) ? acc + claimed : acc
    }, BigInt(0))
    loadingClaimedBalance.value = false
  }

  // Unvested balance

  const unvested = computed(() => {
    return totalTokenAllocation.value - totalClaimedBalance.value - totalClaimableBalance.value
  })

  // Token balance

  const tokenBalance = computed(() => Number(formatEther(BigInt(String(tokenAvailable.value)))))
  const loadingTokenBalance = ref(false)

  async function getTokenBalance() {
    loadingTokenBalance.value = true
    tokenAvailable.value = await readContract(config, {
      abi: tokenContractDefinition.abi,
      functionName: 'balanceOf',
      args: [walletConnected.value],
      address: env.contracts.base.tokenAddress,
    }) as bigint
    loadingTokenBalance.value = false
  }

  return {
    getSnapshot,
    snap,
    totalTokenAllocation,
    allocationTypes,
    loadingData,
    checkClaimable,
    getTokenBalance,
    tokenBalance,
    loadingTokenBalance,
    unvested,
    getAllClaimedBalance,
    totalClaimedBalance,
    loadingClaimedBalance,
    totalClaimableBalance,
    loadingClaimableBalance,
    getAllClaimable,
    myStakes,
    getMyStakes,
    hasStakes,
    hasAirdrop,
  }
}
