
export const walletTruncate = (input: string) => {
  if (!input) return ''
  const separator = '...'

  if (input.length <= 13) return input

  return input.substr(0, 6) +
        separator +
        input.substr( -6)
}

export function formatNumber(value: number, decimals: number): number {
  const formatted = value.toFixed(decimals)
  return parseFloat(formatted)
}

export function formatCamelCase(input: string): string {
  return input
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .trim()
}

export function redirectToService(baseUrl: string, params: Record<string, string | number>): void {
  const queryString = new URLSearchParams(params as Record<string, string>).toString()
  const fullUrl = `${baseUrl}?${queryString}`

  window.open(fullUrl, '_blank')
}