<template>
  <header class="relative">
    <img
      :src="border"
      alt="border"
      class="absolute w-full -z-10"
    >
    <div class="flex max-md:flex-col justify-between max-md:items-center z-10 relative mt-5 md:mt-7">
      <RouterLink
        to="/"
        class="mx-auto md:mx-9 flex items-center"
      >
        <img
          :src="logo"
          alt="Lingo logo"
        >
      </RouterLink>
      <nav
        v-if="showNav"
        class="mr-auto flex flex-col justify-center mx-auto md:ml-11 min-h-[50px]"
      >
        <ul class="flex gap-5 items-center justify-center md:gap-7 uppercase">
          <li>
            <RouterLink
              :to="{ name: earnRouteName }"
              active-class="text-[#DC53FB] border-b border-[#DC53FB]"
              class="text-[16px] leading-[100%] font-korolev font-bold text-white tracking-[3px] py-2"
            >
              Earn
            </RouterLink>
          </li>
          <li>
            <RouterLink
              :to="{ name: LingoRouteName.CLAIMING_DASHBOARD }"
              active-class="text-[#DC53FB] border-b border-[#DC53FB]"
              class="text-[16px] leading-[100%] font-korolev font-bold text-white tracking-[3px] py-2"
            >
              Claim
            </RouterLink>
          </li>
          <li>
            <RouterLink
              :to="{ name: LingoRouteName.BUY }"
              active-class="text-[#DC53FB] border-b border-[#DC53FB]"
              class="text-[16px] leading-[100%] font-korolev font-bold text-white tracking-[3px] py-2"
            >
              Buy
            </RouterLink>
          </li>
        </ul>
      </nav>
      <div class="mx-5 md:mx-7">
        <slot name="right" />
      </div>
    </div>
  </header>
</template>
<script setup lang="ts">
import border from '@/assets/images/rectangle.svg'
import logo from '@/assets/images/logo-lingo.svg'
import { computed } from 'vue'
import { useBalance } from '@/composables/contracts/balance.ts'
import { useRoute } from 'vue-router'
import { LingoRouteName } from '@/router/routes.ts'

const { hasStakes } = useBalance()
const route = useRoute()

const earnRouteName = computed(() => hasStakes.value ? LingoRouteName.STAKING_DASHBOARD : LingoRouteName.START_EARNING)

const showNav = computed(() => route.meta.showNav)
</script>

<style scoped>

</style>