<template>
  <div
    class="min-h-screen flex flex-col items-center justify-between"
  >
    <router-view v-slot="{ Component }">
      <component
        v-if="isHome"
        :is="Component"
        :key="route.path"
      />
      <BaseLayout v-else>
        <component
          :is="Component"
          :key="route.path"
        />
      </BaseLayout>
    </router-view>
  </div>
</template>
<script setup lang="ts">
import { RouterView, useRoute, useRouter } from 'vue-router'
import { init } from './composables/wallet/wallet-init.ts'
import { computed, watch } from 'vue'
import BaseLayout from '@/components/layout/BaseLayout.vue'
import { apiToken } from '@/composables/auth.ts'
import { LingoRouteName } from '@/router/routes.ts'
import { useBalance } from '@/composables/contracts/balance.ts'

const route = useRoute()
const router = useRouter()
const isHome = computed(() => route.name === 'home')
init()
const { getSnapshot } = useBalance()
watch(apiToken, () => {
  if (route.meta.requiresAuth && !apiToken.value) {
    router.push({ name: LingoRouteName.CONNECT_WALLET_V2, query: { redirect: route.fullPath } })
  }
  if (apiToken.value) {
    getSnapshot()
  }
}, { immediate: true })

</script>
<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
