import { apiToken } from '@/composables/auth'
import { callApi } from '@/services/call-api'
import { Beneficiary } from '@/types/beneficiary.ts'
import { Allocation, Snapshot } from '@/types/snapshot.ts'
import { LOCK_DURATION_ID } from '@/types/staking.ts'

export type Stake = {
  id: string
  amount: string
  timestamp: number
  unlockBlock: number
  boost: number
  dailyPower: number
  baseDailyPower: number
}

export class ApiError extends Error {
  constructor(message: string, public readonly metadata?: Record<string, unknown>) {
    super(message)
  }
}

export class Api {
  private async fetch<T>(input: {
    path: string,
    body?: object,
    method: 'POST' | 'DELETE' | 'GET'
  }): Promise<T> {
    return await callApi({
      ...input,
      path: `/api${input.path}`,
      headers: {
        'Authorization': `Bearer ${apiToken.value}`,
      },
    })
  }

  public async getMerkleProof(beneficiary: Beneficiary) {
    return await this.fetch<{ proof: string[] }>({
      path: '/staking/merkle-proof',
      method: 'POST',
      body: {
        beneficiary,
      },
    })
  }

  public async getStakes() {
    return await this.fetch<{ stakes: Stake[], powers: { total: string } & Record<string, string> }>({
      path: '/staking/stakes',
      method: 'POST',
    })
  }

  public async getConfig() {
    return await this.fetch<{
      lockDurations: Record<LOCK_DURATION_ID, { lockDurationBlocks: number, boost: number, index: number }>
      power: {
        base: number
        decimalCount: number
      } }>({
        path: '/staking/config',
        method: 'GET',
      })
  }

  public async getSnapshotV2() {
    return await this.fetch<{ snapshot: Snapshot | null }>({
      path: '/staking/snapshot',
      method: 'GET',
    })
  }

  public async getAllocations() {
    return await this.fetch<{ allocations: Allocation[] }>({
      path: '/staking/allocation',
      method: 'GET',
    })
  }

  public async submitKucoinId(kucoinId: string) {
    return await this.fetch<{ success: boolean, message: string }>({
      path: '/open-form/kucoin',
      method: 'POST',
      body: {
        kucoinId,
      },
    })
  }

  public async getQuote(amount: number, currency: string, convertedCurrency: string, blockchain: string, quoteCurrency: string) {
    return await this.fetch<{ data: { payment_method: string, converted_amount: string }[] }>({
      path: '/ramp/buy/quote',
      method: 'POST',
      body: {
        amount,
        currency,
        converted_currency: convertedCurrency,
        blockchain,
        quote_currency: quoteCurrency,
      },
    })
  }

  public async checkTransaction(txHash: string) {
    await this.fetch<{ success: boolean }>({
      path: '/staking/check-tx',
      method: 'POST',
      body: {
        txHash,
      },
    })
  }

  public async refreshStakes() {
    await this.fetch({
      path: '/staking/refresh',
      method: 'POST',
    })
  }
}

export default new Api()
