import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue'

import { base, baseSepolia } from 'viem/chains'
import { createConfig, http, injected, reconnect } from '@wagmi/core'
import { createClient } from 'viem'
import { coinbaseWallet, walletConnect } from '@wagmi/connectors'
import { env } from '@/env.ts'
import { magicEdenConnector } from '@/composables/wallet/magic-eden-wallet.ts'

// 1. Define constants
const projectId = 'b1297ecf5f17439296d9cde233b7f56e'

// 2. Create wagmiConfig
const metadata = {
  name: 'Lingo token',
  description: 'Lingo token',
  url: env.app.url, // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const CONNECTORS = [
  walletConnect({ projectId, metadata, showQrModal: false }),
  injected({ shimDisconnect: true }),
  coinbaseWallet({
    appName: metadata?.name ?? 'Unknown',
    appLogoUrl: metadata?.icons[0] ?? 'Unknown',
    enableMobileWalletLink: true,
  }),
  magicEdenConnector,
]

const chains = [env.app.isProd ? base : baseSepolia] as const
export const defaultConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
})

export const config = createConfig({
  ...defaultConfig,
  client({ chain }) {
    return createClient({ chain, transport: http(env.contracts.rpcEndpoint) })
  },
  connectors: CONNECTORS,
})

export async function init() {
  createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
  })

  await reconnect(config)
}
