export enum Beneficiary {
  KOLRoundA,
  KOLRoundB,
  KOLRoundFreeAllocation,
  LingoIslandsAirdrop,
  LingoIslandsAirdropFirstClass,
  PartnersAirdrop,
  PartnersAirdropFirstClass,
  PrivateRound3MPostTGEUnlock,
  PrivateRoundA,
  PrivateRoundB,
  PrivateRoundC,
  PrivateRoundD,
  PrivateRoundE,
  PrivateRoundF,
  PublicPresale,
  PublicPresaleFirstClass,
  PublicRound,
  Team,
}

export const beneficiaryMapping: Record<string, Beneficiary> = {
  KOLRoundA: Beneficiary.KOLRoundA,
  KOLRoundB: Beneficiary.KOLRoundB,
  KOLRoundFreeAllocation: Beneficiary.KOLRoundFreeAllocation,
  LingoIslandsAirdrop: Beneficiary.LingoIslandsAirdrop,
  LingoIslandsAirdropFirstClass: Beneficiary.LingoIslandsAirdropFirstClass,
  PartnersAirdrop: Beneficiary.PartnersAirdrop,
  PartnersAirdropFirstClass: Beneficiary.PartnersAirdropFirstClass,
  PrivateRound3MPostTGEUnlock: Beneficiary.PrivateRound3MPostTGEUnlock,
  PrivateRoundA: Beneficiary.PrivateRoundA,
  PrivateRoundB: Beneficiary.PrivateRoundB,
  PrivateRoundC: Beneficiary.PrivateRoundC,
  PrivateRoundD: Beneficiary.PrivateRoundD,
  PrivateRoundE: Beneficiary.PrivateRoundE,
  PrivateRoundF: Beneficiary.PrivateRoundF,
  PublicPresale: Beneficiary.PublicPresale,
  PublicPresaleFirstClass: Beneficiary.PublicPresaleFirstClass,
  PublicRound: Beneficiary.PublicRound,
  Team: Beneficiary.Team,
}

export const beneficiaryNames: Record<string, string> = {
  KOLRoundA: 'KOL Round A',
  KOLRoundB: 'KOL Round B',
  KOLRoundFreeAllocation: 'KOL Round Free Allocation',
  LingoIslandsAirdrop: 'Lingo Islands Airdrop',
  LingoIslandsAirdropFirstClass: 'Lingo Islands Airdrop First Class',
  PartnersAirdrop: 'Partners Airdrop',
  PartnersAirdropFirstClass: 'Partners Airdrop First Class',
  PrivateRound3MPostTGEUnlock: 'Private Round 3M Post TGE Unlock',
  PrivateRoundA: 'Private Round A',
  PrivateRoundB: 'Private Round B',
  PrivateRoundC: 'Private Round C',
  PrivateRoundD: 'Private Round D',
  PrivateRoundE: 'Private Round E',
  PrivateRoundF: 'Private Round F',
  PublicPresale: 'Public Presale',
  PublicPresaleFirstClass: 'Public Presale First Class',
  PublicRound: 'Public Round',
  Team: 'Team',
}

