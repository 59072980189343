<template>
  <button
    class="button"
    :class="[styleType, disabled ? 'disabled' : '']"
    :data-style="styleType"
    type="button"
    :disabled="disabled"
    @click="buttonClick"
  >
    <span
      v-if="icon"
      class="inline-flex items-center justify-center gap-2 relative"
    >
      <img
        :src="icon"
        alt="icon"
        class="leading-6 h-6 w-auto absolute -left-8"
        :class="iconClass"
      >
      <slot />
    </span>
    <slot v-else />
  </button>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
      styleType?:
          | 'primary'
          | 'secondary'
          | 'tertiary'
          | 'transparent'
      disabled?: boolean
      icon?: string
      iconClass?: string
    }>(),
  {
    styleType: 'primary',
    disabled: false,
    icon: undefined,
    iconClass: '',
  },
)

const buttonClick = (event: Event) => {
  // using try-catch to avoid event bubbling stop on any error
  try {
    const targetElement = event.currentTarget as HTMLElement
    if (targetElement) {
      targetElement.blur()
    }
  } catch (e) {
    console.log(e)
  }
}
</script>

<style lang="scss" scoped>
.button {
  @apply px-6 py-4 uppercase font-korolev text-base font-bold leading-none tracking-[3px] rounded enabled:hover:opacity-80;

  &[data-style="primary"] {
    @apply bg-white text-black;
  }

  &[data-style="secondary"] {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
    border: 1px solid rgba(255, 255, 255, 0.30);
  }

  &[data-style="tertiary"] {
    @apply text-black bg-opacity-50 shadow-[0_0_20px_0_#DC53FB];
    background: url("@/assets/images/button-bg.png") no-repeat center/cover, lightgray;
    background-color: #FFF;

    &.disabled {
      @apply shadow-none;
    }
  }

  &[data-style="transparent"] {
    @apply text-white bg-transparent p-0 !important;
  }

  &.disabled {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    color: white;
    opacity: 0.3;
  }

}
</style>
