import { prefix } from '@/router/tge.ts'

export enum LingoRouteName {
    DASHBOARD = 'Dashboard',
    CLAIMING_DASHBOARD = 'ClaimingDashboard',
    CLAIMING = 'Claiming',
    STAKING_DASHBOARD = 'StakingDashboard',
    STAKING = 'Staking',
    STAKING_RENEW = 'StakingRenew',
    START_EARNING = 'StartEarning',
    CONNECT_WALLET_V2 = 'ConnectWalletV2',
    CONNECT_WALLET = 'ConnectWallet',
    BUY = 'Buy',
    TERMS_AND_CONDITIONS = 'TermsAndConditions',
    PRIVACY_POLICY = 'PrivacyPolicy',
}

export const lingoRoutePath: Record<LingoRouteName, string> = {
  [LingoRouteName.DASHBOARD]: '/dashboard',
  [LingoRouteName.CLAIMING_DASHBOARD]: `${prefix}/claiming-dashboard`,
  [LingoRouteName.CLAIMING]: `${prefix}/claiming-dashboard/claim`,
  [LingoRouteName.STAKING_DASHBOARD]: `${prefix}/staking-dashboard`,
  [LingoRouteName.STAKING]: `${prefix}/staking-dashboard/stake`,
  [LingoRouteName.STAKING_RENEW]: `${prefix}/staking-dashboard/stake-renew/:id`,
  [LingoRouteName.START_EARNING]: `${prefix}/start-earning`,
  [LingoRouteName.CONNECT_WALLET_V2]: `${prefix}/connect`,
  [LingoRouteName.CONNECT_WALLET]: '/connect-wallet',
  [LingoRouteName.BUY]: `${prefix}/buy`,
  [LingoRouteName.TERMS_AND_CONDITIONS]: `${prefix}/terms-and-conditions`,
  [LingoRouteName.PRIVACY_POLICY]: `${prefix}/privacy-policy`,
}
