<script setup lang="ts">
import BaseButton from '@/components/base/BaseButton.vue'
import { apiToken, walletConnected } from '@/composables/auth.ts'
import { walletTruncate } from '@/composables/helpers.ts'
import { useWalletAuth } from '@/composables/wallet/use-wallet-auth.ts'

const { logout } = useWalletAuth()
</script>

<template>
  <div
    v-if="apiToken"
    class="w-full p-1 rounded-lg"
  >
    <BaseButton
      class="w-full flex flex-col gap-1.5"
      style-type="transparent"
      @click="logout()"
    >
      <span class="text-white/60 text-xs leading-none">Connected wallet</span>
      <span>{{ walletTruncate(walletConnected) }}</span>
    </BaseButton>
  </div>
</template>

<style scoped>

</style>
