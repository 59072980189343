/* eslint-disable  @typescript-eslint/no-explicit-any */
import { injected } from '@wagmi/connectors'

// Extend the global Window interface
declare global {
  interface Window {
    magicEden?: {
      ethereum?: {
        request: (args: { method: string; params?: any[] }) => Promise<any>;
        on: (event: string, handler: (...args: any[]) => void) => void;
        removeAllListeners: () => void;
        // Add other methods and properties as needed
      };
    };
  }
}

export const MAGIC_EDEN_ID = 'io.magiceden.wallet'

// Define the Magic Eden Wallet Connector
export const magicEdenConnector = injected({
  target() {
    const provider = typeof window !== 'undefined' ? window.magicEden?.ethereum : undefined
    return {
      id: MAGIC_EDEN_ID,
      name: 'Magic Eden',
      icon: new URL(`/src/assets/images/magic-eden-logo.jpeg`, import.meta.url).href,
      provider: provider as any, // We still use `any` here to bypass strict type checking for the provider from WAGMI
    }
  },
})
