<template>
  <div class="h-full w-full min-h-screen flex flex-col p-3">
    <BaseTopBar class="mb-2 md:mb-10">
      <template #right>
        <div class="flex flex-col sm:flex-row space-1 gap-5 p-1">
          <!--          <BaseButton-->
          <!--            style-type="tertiary"-->
          <!--            class="whitespace-pre"-->
          <!--            @click="showInviteModal = true"-->
          <!--          >-->
          <!--            Refer & Earn-->
          <!--          </BaseButton>-->
          <DisconnectWallet v-if="apiToken" />
          <ConnectWalletButton
            v-if="!apiToken && isEarn"
            button-style="secondary"
            class="max-md:hidden"
          />
        </div>
      </template>
    </BaseTopBar>
    <div class="flex-1">
      <slot />
    </div>
    <BaseFooter class="px-4 md:px-9 py-9" />
    <!--    <InviteModal-->
    <!--      :show="showInviteModal"-->
    <!--      @hide="showInviteModal = false"-->
    <!--    />-->
  </div>
</template>
<script setup lang="ts">
import BaseTopBar from '@/components/layout/BaseTopBar.vue'
import BaseFooter from '@/components/layout/BaseFooter.vue'
import ConnectWalletButton from '@/components/ConnectWalletButton.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import DisconnectWallet from '@/components/DisconnectWallet.vue'
import { apiToken } from '@/composables/auth.ts'
import { LingoRouteName } from '@/router/routes.ts'

const route = useRoute()
const isEarn = computed(() => route.name === LingoRouteName.START_EARNING)

</script>